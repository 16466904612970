<template>
	<div class="main-error-body bg-primary-transparent">
		<div class="page">
			<div class="main-error-wrapper  page page-h ">
				<img src="/assets/img/error/401.png" class="error-page" alt="error">                
				<h2>Ооппс. Запрос не был выполнен</h2>
				<h6> Страница, к которой вы пытались получить доступ, не может быть загружена, пока вы не войдёте в систему с действительным идентификатором пользователя и паролем.</h6>
                <a class="btn btn-outline-danger" href="/">Вернуться на главную страницу</a>
			</div>			
		</div>
	</div>
</template>

<script>
export default {
}
</script>